import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

// import {PeopleListComponent} from './components/web/people-list/people-list.component';
// import {MobilePeopleListComponent} from './components/mobile/people-list/mobile-people-list.component';
// import {PeopleReportComponent} from './components/web/people-report/people-report.component';

// TESTING::
import { PeopleListComponent } from './components/web/people-list/people-list.component';
import { PeopleSidebarComponent} from './components/web/people-sidebar/people-sidebar.component';
import { PeopleReportComponent } from './components/web/people-report/people-report.component';
import { MobilePeopleListComponent } from './components/mobile/people-list/mobile-people-list.component';
import { MobilePeopleFullViewComponent } from './components/mobile/people-full-view/mobile-people-full-view.component';
import { AuthGuardService } from '../app/services/auth-guard.service';
import { PeopleAssistantModalComponent } from './components/web/people-assistant-modal/people-assistant-modal.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path      : 'people',
                redirectTo: '/people/list',
                pathMatch : 'full'
            },
            {
                path      : 'm/people',
                redirectTo: 'm/people/list',
                pathMatch : 'full'
            },
            {
                path       : 'people/list',
                component  : PeopleListComponent,
                canActivate: [AuthGuardService]
            },
            {
                path       : 'm/people/list',
                component  : MobilePeopleListComponent,
                canActivate: [AuthGuardService]
            },
            {
                path       : 'm/people/view/:personToDisplayId/:reportType',
                component  : MobilePeopleFullViewComponent,
                canActivate: [AuthGuardService]
            },
            {
                path       : 'people/report/:personToDisplayId/:reportType',
                component  : PeopleReportComponent,
                canActivate: [AuthGuardService]
            },
            {
                path       : 'people/report/:personToDisplayId/:reportType/sherpa',
                component  : PeopleAssistantModalComponent,
                canActivate: [AuthGuardService]
            }

        ])
    ],
    exports: [
        RouterModule
    ]
})
export class PeopleRoutingModule {
}
