import { 
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, 
    HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation 
} from '@angular/core';
import { 
    trigger, style, transition, animate 
} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ApiPeopleService } from '@people/services/api-people.service';
import { PersonService } from '@people/services/person.service';
import { MarkdownToHtmlHelper } from '@shared/helpers/markdown-to-html.helper';
import { StateService } from '@core/services/state/state.service';
import { switchMap, timeoutWith, catchError } from 'rxjs/operators';
import { Subscription, throwError, Subject } from 'rxjs';
import { UserService } from '@core/services/user/user.service';
import { ApiJobsService } from 'src/app/jobs/services/api-jobs.service';
import { ComponentFullView } from '../../../../shared/classes/components/component_full_view';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import {PeopleModalHandlingService} from '../../../services/people-modal-handling.service';

type DataType = {
    question: string;
    currentLang: string;
    experimental: string;
    personalityScores: { [key: string]: string };
    iacScores?: string;
    prbScores?: { [key: string]: string };
    talentsScores?: any;
    feedback?: string; // Added feedback property
    feedbackType?: string; // Added feedbackType property
    response?: string;
    
};
@Component({
    selector: 'app-people-assistant-modal',
    standalone: false,
    templateUrl: './people-assistant-modal.component.html',
    styleUrls: ['./people-assistant-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('reasonAnimation', [
            transition(':enter', [ // When the element is added to the DOM
                style({ 
                    opacity: 0, 
                    height: '0px' 
                }),
                animate('300ms ease-out', style({ // 300ms delay before animation starts
                    opacity: 1, 
                    height: '*' 
                }))
            ]),
            transition(':leave', [ // When the element is removed from the DOM
                animate('300ms ease-in', style({ 
                    opacity: 0, 
                    height: '0px' 
                }))
            ])
        ])
    ]
})


export class PeopleAssistantModalComponent extends ComponentFullView implements OnInit, OnDestroy {
    @ViewChild('checkbox') checkbox: ElementRef;

    txt = 'JIL';
    characters = this.txt.split('');
    reportWidth: string;
    sideWidth: string;
    small = true;
    hideGraph = false;
    public close: EventEmitter<any> = new EventEmitter();
    private subscriptions = new Subscription();
    private onDestroy = new Subject<void>();
    
    otherIconsFolder = 'assets/other_icons/';

    feedbackMode: boolean = false;
    feedback: string = '';
    feedbackType: string = '';
    feedbackSubmitted = false;
    questionAsked: boolean = false;

    showReasons: boolean = false;

    reasons: any[] = [];
    selectedReason: any;
    reasonSelected: boolean = false;
    responseReceived: boolean = false;

    questionSent: boolean = false;

    sectionVisible: boolean = true;
    iconClicked: boolean = false;

    chatWindowVisible:boolean = false;

    userInput: string;
    userQuestion: string;
    messages = { response: '' };
    response = '';

    isExperimental = false;
    isShortAnswer = false;

    loadingState = 0;
    additionalLoading: boolean = false;
    loadingIntervalId: any;
    timeoutId: any;


    // *************************************************
    // https://ngenio.atlassian.net/browse/MPO-3792    *
    // Pour la démo en France nous desactivons le      *
    // requis d'acceptation des termes et conditions   *
    // *************************************************
    firstTimeUser = false;
    isErrorMessage = false;
    selectedQuestion: string;
    openSidebar = false;
    aiContext = 'traits';
    copySuccess = false;
    currentSelection: string;
    actualContext = {};
    placeholderText: string;

    showPcrMessage: boolean = false;

    termsOfServiceHtml: string;

    report: any;
    selectedCategory: any;
    categorySelected = false;
    langChangeSubscription: Subscription;
    reducedChatHeight: boolean = false;
    reduceChatHeightFeedback: boolean = false;

    selectedOption = 'traits';

    lastAskedQuestion: string;

    isHelpMenuVisible: boolean = false;
    isFAQVisible: boolean = false;
    isTermsVisible: boolean = false;

    termsAccepted: boolean = false;
    termsAcceptedConfirmed: boolean = false;

    isInputFocused: boolean = false;
    hasAskedQuestion: boolean = false;

    isSmallViewport: boolean = false;

    buttonStateAndIndex = {
        personaliteSelected: { selected: false, categoryIndex: 0 },
        pcrSelected: { selected: false, categoryIndex: 1 },
        talentsSelected: { selected: false, categoryIndex: 2 },
        iacSelected: { selected: false, categoryIndex: 3 },
    };

    constructor(
        public bsModalRef: BsModalRef,
        public cd: ChangeDetectorRef,
        protected stateService: StateService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected person: PersonService,
        protected translateService: TranslateService,
        protected user: UserService,
        protected deviceService: DeviceDetectorService,
        private apiPeople: ApiPeopleService,
        private apiJobs: ApiJobsService,
        private http: HttpClient,
        private peopleModalHandlingService: PeopleModalHandlingService
    ) {
        super(stateService, translateService, router, deviceService);

        this.loadQuestions();
        this.translateService.onLangChange.subscribe(() => this.loadQuestions());

        this.loadReasons();
        this.translateService.onLangChange.subscribe(() => this.loadReasons());

        this.checkViewportHeight();

    }
    

    checkViewportHeight() {
        this.isSmallViewport = window.innerHeight < 925;
    }

    ngOnInit() {
        console.log('Viewport inner width:', window.innerWidth);
        // Sidebar
        this.small = window.innerWidth < 1235;
        this.stateService.people.stateChanged$.subscribe((res) => {
            if (res) {
                this.cd.markForCheck();
            }
        });
        this.hideGraph = true;
        this.subscriptions.add(
            this.apiJobs.jobs([{ fields: 'id' }]).subscribe((checkJobs) => {
                this.hideGraph = false;
                this.cd.markForCheck();
            }),
        );
        // Set list width
        this.computeReportWidth(window);
        const reportType = this.stateService.people.reportType;
        switch (reportType) {
            case 'personality':
                this.toggleButton('personaliteSelected');
                this.currentSelection = 'personaliteSelected';
                this.selectedOption = 'traits';
                this.aiContext = 'traits';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                break;
            case 'perceptions':
                this.toggleButton('pcrSelected');
                this.currentSelection = 'pcrSelected';
                this.selectedOption = 'perceptions';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_perceptions';
                this.aiContext = 'perceptions';
                break;
            case 'talents':
                this.toggleButton('talentsSelected');
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_talents';
                this.aiContext = 'talents';
                break;
            case 'iac':
                this.toggleButton('iacSelected');
                this.currentSelection = 'iacSelected';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_iac';
                this.aiContext = 'iac';
                break;
            default:
                this.toggleButton('personaliteSelected');
                this.currentSelection = 'personaliteSelected';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder';
                this.aiContext = 'traits';
                break;
        }
        this.loadQuestions();
        this.loadReasons();
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: { lang: string }) => {
            this.translateService.getTranslation(event.lang).subscribe(() => {
                this.loadQuestions();
                this.loadReasons();
                this.buttonStateAndIndex.personaliteSelected.selected = true;
                this.selectedOption = 'traits';
                this.cd.detectChanges();
            });
        });
    }

    loadReasons() {
        const lang = this.translateService.currentLang;
        const fileName = `assets/assistant-thumb-down-reasons/reasons_${lang}.json`;
        this.http.get(fileName).subscribe((data: any) => {
            this.reasons = data[0].reasons;
        });
    }

    onResize(event) {
        // re-Set list width
        this.computeReportWidth(event.target);
        this.small = event.target.innerWidth < 1235;
    }

    computeReportWidth(startWidth) {
        let adjustment: any = { left: -5, right: -8 };
        let widthRatio: number = 2 / 3;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (startWidth.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.reportWidth = (innerWidth - leftColumnWidth - 39) * widthRatio + adjustment.left + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth - 39) * (1 - widthRatio) + adjustment.right + 'px';
        this.sideWidth = '400px';
        this.reportWidth = innerWidth - 550 + 'px';
        return;
    }

    startLoadingAnimation() {
        this.loadingState = 0;
        this.additionalLoading = false;
    
        this.loadingIntervalId = setInterval(() => {
            this.loadingState = (this.loadingState + 1) % 4;
            this.cd.detectChanges(); // Trigger change detection
        }, 500);
    
        this.timeoutId = setTimeout(() => {
            this.additionalLoading = true;
            this.cd.detectChanges(); // Trigger change detection
        }, 9000);
    }

    stopLoadingAnimation() {
        if (this.loadingIntervalId) {
            clearInterval(this.loadingIntervalId);
            this.loadingIntervalId = null;
        }
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        this.cd.detectChanges(); // Trigger change detection
    }
    

    askQuestion() {
        if (this.questionAsked) { // Ensure not to call submitFeedback on the first question
            this.submitFeedback();
        }
        this.hasAskedQuestion = true;
        this.isErrorMessage = false;
        this.messages = { response: '' };
        this.additionalLoading = false;
        this.userInput = this.selectedQuestion;
        this.userQuestion = this.userInput;
        this.questionAsked = true;
        this.chatWindowVisible = true;
        this.reducedChatHeight = true;
        this.cd.detectChanges();
        this.cd.markForCheck();
        this.questionSent = true;
        this.cd.detectChanges();
        this.cd.markForCheck();
        this.responseReceived = true; // Disable buttons initially

        this.startLoadingAnimation();

        // Set lastAskedQuestion before clearing selectedQuestion
        this.lastAskedQuestion = this.userInput; // Store the last question asked in a different variable
        this.cd.detectChanges();
        this.cd.markForCheck();

        const currentLang = this.translateService.currentLang;
        const data = this.gatherQuestionContext();
        this.apiPeople
            .questionAi(data, this.aiContext)
            .pipe(
                timeoutWith(30000, throwError('people.commons.request_timeout_error')),
                catchError((error) => {
                    let errorKey;
                    if (error.status === 500) {
                        errorKey = 'people.commons.server_error';
                    } else if (error === 'people.commons.request_timeout_error') {
                        errorKey = 'people.commons.request_timeout_error';
                    } else {
                        errorKey = error;
                    }

                    return this.translateService.get(errorKey).pipe(
                        switchMap((translatedErrorMessage) => {
                            clearInterval(this.loadingIntervalId);
                            clearTimeout(this.timeoutId);
                            this.isErrorMessage = true;
                            console.error('An error occurred:', error);
                            this.responseReceived = true; // Enable buttons on error
                            this.questionSent = true;
                            this.cd.detectChanges();
                            this.cd.markForCheck();
                            return throwError(translatedErrorMessage);
                        }),
                    );
                }),
            )
            .subscribe(
                (response: any) => {
                    this.stopLoadingAnimation();
                    clearInterval(this.loadingIntervalId);
                    clearTimeout(this.timeoutId);
                    this.actualContext = response;
                    this.messages = {
                        response: MarkdownToHtmlHelper.markdownToHtml(response.response),
                    };
                    this.responseReceived = true; // Enable buttons on success
                    this.reducedChatHeight = false;
                    this.questionSent = true;
                    this.isErrorMessage = false;
                    this.sectionVisible = false;
                    this.cd.detectChanges();
                    this.cd.markForCheck();
                },
                (error) => {
                    this.stopLoadingAnimation();
                    this.messages = {
                        response: error,
                    };
                    this.responseReceived = true; // Enable buttons on error
                    this.questionSent = true;
                    this.isErrorMessage = true;
                    this.sectionVisible = false;
                    this.cd.detectChanges();
                    this.cd.markForCheck();
                },
            );

        // Clear the input field after the question is asked
        this.selectedQuestion = '';
    }
    

    submitFeedback() {
        // Gather the context data
        const context = this.gatherQuestionContext();
        context.feedback = this.selectedReason ? this.selectedReason.reasonText : this.feedback;
        context.feedbackType = this.feedbackType;
        context.response = this.messages.response;
        // Save the context
        this.saveContext(context);
    
        // Reset the necessary properties
        this.userInput = '';
        this.userQuestion = '';
        this.selectedReason = null; // Reset the selected reason
        this.messages = { response: '' };
        this.questionAsked = false;
        this.feedbackMode = false;
        this.iconClicked = false;
        this.feedbackType = ''; // Reset feedback type
    
        this.feedbackSubmitted = true;
    }

    submitThumbsUp() {
        this.iconClicked = !this.iconClicked;
        this.selectedReason = null;
        if (this.iconClicked) {
            this.feedbackMode = false; // Ensure thumbs down is not selected
            this.showReasons = false; // Hide reasons when thumbs up is clicked
            this.feedbackType = 'thumbsUp'; // Set feedback type to thumbs up
            this.feedbackMode = false;
        } else {
            this.feedbackType = ''; // Reset feedback type if unclicked
        }
    }

    saveContext(context: DataType) {
        this.apiPeople.saveAiContext(context).subscribe(() => { });
    }

    selectReason(reason: any) {
        this.selectedReason = reason;
        this.reasonSelected = true;
        this.showReasons = false;
     }

      giveFeedback() {
        this.feedbackMode = !this.feedbackMode;
        if (this.feedbackMode) {
            this.iconClicked = false; // Ensure thumbs up is not selected
            this.showReasons = true;
            this.reduceChatHeightFeedback = true;
            this.feedbackType = 'thumbsDown'; // Set feedback type to thumbs down
            if (this.sectionVisible) {
                this.toggleSection(); // Collapse the section if it's visible
            }
        } else {
            this.showReasons = false;
            this.reduceChatHeightFeedback = false;
            this.feedbackType = ''; // Reset feedback type if unclicked
            // this.reducedChatHeight = false;
        }
    }

    acceptTerms() {
        this.firstTimeUser = false;
        // No need to toggle termsAccepted here
    }

    closeModal(): void {
        this.close.emit({ closed: true });
        this.bsModalRef.hide();
    }

    thumbsIconClicked() {
        this.iconClicked = !this.iconClicked;
    }

    onQuestionSelected(question: string) {
        this.selectedQuestion = question;
    }

    copyToClipboard() {
        let responseText = this.HTMLToText(this.messages?.response);
        this.translateService.get(['people.commons.question', 'people.commons.response']).subscribe((translations) => {
            const clipboardText = `${translations['people.commons.question']}: ${this.userQuestion}\n\n${translations['people.commons.response']}: ${responseText}`;

            navigator.clipboard.writeText(clipboardText).then(() => {
                this.copySuccess = true;
                this.cd.detectChanges();
                
                setTimeout(() => {
                    this.copySuccess = false;
                    this.cd.detectChanges();
                }, 2000);
            });
        });
    }

    toggleButton(button: string) {
        if (this.buttonStateAndIndex[button].selected) {
            return;
        }

        for (let key in this.buttonStateAndIndex) {
            this.buttonStateAndIndex[key].selected = false;
        }

        this.buttonStateAndIndex[button].selected = true;
        this.showPcrMessage = false;
        this.aiContext = 'traits';
        this.userInput = '';
        this.selectedQuestion = '';

        let reportType = '';

        if (button === 'personaliteSelected') {
            this.aiContext = this.selectedOption;
            this.showPcrMessage = true;
            reportType = 'personality';
        } else if (button === 'talentsSelected') {
            this.aiContext = 'talents';
            this.showPcrMessage = false;
            reportType = 'talents';
        } else if (button === 'iacSelected') {
            this.aiContext = 'iac';
            this.showPcrMessage = false;
            reportType = 'iac';
        } else if (button === 'pcrSelected') {
            this.aiContext = 'pcr';
            this.showPcrMessage = false;
            reportType = 'pcr';
        }

        switch (this.aiContext) {
            case 'traits':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                break;
            case 'pcr':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_perceptions';
                break;
            case 'talents':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_talents';
                break;
            case 'iac':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_iac';
                break;
            default:
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder';
                break;
        }
        this.selectedQuestion = '';
        this.switchEvaluationType(reportType);
    }

    // Ensure switchEvaluationType is accessible in this component
    switchEvaluationType(newType: string) {
        if (!this.peopleModalHandlingService.checkReporUnaccessble(newType)) {
            let change = { reportType: newType };
            this.statePeople.reportType = newType;
            this.statePeople.stateChanged.next(change);
        }
    }

    onRadioChange() {
        if (this.buttonStateAndIndex['personaliteSelected'].selected) {
            this.aiContext = this.selectedOption;
            switch (this.aiContext) {
                case 'traits':
                    this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                    break;
                case 'perceptions':
                    this.placeholderText = 'commons.assistantVirtuel_input_placeholder_perceptions';
                    break;
            }
         }
    }


    toggleSidebar() {
        this.openSidebar = !this.openSidebar;
    }

    toggleCheckbox(event: Event) {
        this.checkbox.nativeElement.checked = !this.checkbox.nativeElement.checked;
        this.termsAccepted = this.checkbox.nativeElement.checked;
        this.acceptTerms();
    }

    // Method to toggle the visibility of the section and adjust the chat height accordingly
    toggleSection() {
        // Toggle the visibility of the section
        this.sectionVisible = !this.sectionVisible;
        // Set reducedChatHeight to true only when sectionVisible is true
        this.reducedChatHeight = this.sectionVisible;

        this.showReasons = false;

        // If the section is collapsed, go back to categories

        this.categorySelected = false;
    }

    toggleHelpMenu() {
        this.isHelpMenuVisible = !this.isHelpMenuVisible;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event) {
        const target = event.target as HTMLElement;
        if (!target.closest('.help-button-container')) {
            this.isHelpMenuVisible = false;
        }
    }

    showFAQ() {
        this.isFAQVisible = true;
        this.isTermsVisible = false;
        this.isHelpMenuVisible = false;
    }

    hideFAQ() {
        this.isFAQVisible = false;
        this.isTermsVisible = false;
    }

    showTerms() {
        this.isFAQVisible = false;
        this.isTermsVisible = true;
        this.isHelpMenuVisible = false;
    }

    hideTerms() {
        this.isFAQVisible = false; // or set to the appropriate state for the regular view
        this.isTermsVisible = false;
        this.isHelpMenuVisible = true; // or set to the appropriate state for the regular view
    }

    continue() {
        if (this.termsAccepted) {
            this.termsAcceptedConfirmed = true;
            this.hideTerms();
        }
    }

    HTMLToText(html: string): string {
        let text = html.replace(/<\/p>/g, '\n\n');
        text = text.replace(/<[^>]*>/g, '');
        text = text.replace(/\n\s*\n/g, '\n\n');
        return text;
    }

    isPcrValid(): number {
        let lastReport = this.person.lastPrbReport;
        if (lastReport && lastReport.info && lastReport.info.date) {
            let today = new Date();
            let prbDate = new Date(lastReport.info.date);
            let diffDate = Math.floor((today.getTime() - prbDate.getTime()) / 86400000);
            if (diffDate >= 120) {
                return 2;
            } else if (diffDate >= 60) {
                return 1;
            } else {
                return 0;
            }
        }
        return 0;
    }

    selectCategory(category) {
        this.selectedCategory = category;
        this.categorySelected = true;
    }
    goBack() {
        this.categorySelected = false;
    }

    selectQuestion(question: string, inputElement: HTMLInputElement) {
        this.selectedQuestion = question;
        this.moveCursorToEnd(inputElement);
     }

    loadQuestions() {
        const lang = this.translateService.currentLang;
        const fileName = `assets/assistant-questions/questions_${lang}.json`;
        this.http.get(fileName).subscribe((data: any[]) => {
            this.report = data.find(report => report.reportId === "1");
        });
    }

    refreshQuestion() {
        this.userInput = this.lastAskedQuestion;
        this.askQuestion();
    }

    moveCursorToEnd(inputElement: HTMLInputElement): void {
        setTimeout(() => {
            inputElement.scrollLeft = inputElement.scrollWidth;
        }, 0);
    }

    private gatherQuestionContext(): DataType {
        const currentLang = this.translateService.currentLang;
        let data: DataType = {
            question: this.userInput,
            currentLang: currentLang,
            experimental: this.isExperimental ? 'true' : 'false',
            personalityScores: {},

        };
    
        for (let trait of ['OR', 'SE', 'A', 'E', 'P', 'S']) {
            if (this.person.personalityScores[trait] !== undefined) {
                data.personalityScores[trait] = this.person.personalityScores[trait].toString();
            }
        }
    
        if (this.aiContext === 'iac') {
            if (this.person.iacNormalizedScore !== undefined) {
                data.iacScores = this.person.iacNormalizedScore.toString();
            }
        }
    
        if (this.aiContext === 'perceptions') {
            data.prbScores = {};
            for (let trait of ['OR', 'SE', 'A', 'E', 'P', 'S']) {
                if (this.person.prbScores[trait] !== undefined) {
                    data.prbScores[trait] = this.person.prbScores[trait].toString();
                }
            }
        }
    
        if (this.aiContext === 'talents') {
            data.talentsScores = this.person.talentsReport.info.talentsScore;
        }
    
        return data;
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.submitFeedback();
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    get statePeople(): any {
        return this.stateService.people;
    }

    get isInputInvalid(): boolean {
        if (this.aiContext === 'pcr' && this.isPcrValid() === 2) {
            return true;
        }
        return (!this.userInput || this.userInput.length < 1) && (!this.selectedQuestion || this.selectedQuestion.length < 1);
    }
}