import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter,
    HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import {
    trigger, style, transition, animate,
    state
} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { PeopleList } from '@people/services/people_list.service';
import { ApiPeopleService } from '@people/services/api-people.service';
import { PersonService } from '@people/services/person.service';
import { MarkdownToHtmlHelper } from '@shared/helpers/markdown-to-html.helper';
import { StateService } from '@core/services/state/state.service';
import { switchMap, timeoutWith, catchError, debounceTime } from 'rxjs/operators';
import { Subscription, throwError, Subject, fromEvent } from 'rxjs';
import { UserService } from '@core/services/user/user.service';
import { ApiJobsService } from 'src/app/jobs/services/api-jobs.service';
import { ComponentFullView } from '../../../../shared/classes/components/component_full_view';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { PeopleModalHandlingService } from '../../../services/people-modal-handling.service';

type DataType = {
    question: string;
    currentLang: string;
    experimental: string;
    personalityScores: { [key: string]: string };
    iacScores?: string;
    prbScores?: { [key: string]: string };
    // talentsScores?: any; // Uncomment if talents are re added
    feedback?: string; // Added feedback property
    feedbackType?: string; // Added feedbackType property
    response?: string;
    person_1_scores?: { [key: string]: string };
    person_2_scores?: { [key: string]: string };
    person_1_name?: string;
    person_2_name?: string;


};
@Component({
    selector: 'app-people-assistant-modal',
    standalone: false,
    templateUrl: './people-assistant-modal.component.html',
    styleUrls: ['./people-assistant-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('reasonAnimation', [
            transition(':enter', [ // When the element is added to the DOM
                style({
                    opacity: 0,
                    height: '0px'
                }),
                animate('300ms ease-out', style({ // 300ms delay before animation starts
                    opacity: 1,
                    height: '*'
                }))
            ]),
            transition(':leave', [ // When the element is removed from the DOM
                animate('300ms ease-in', style({
                    opacity: 0,
                    height: '0px'
                }))
            ])
        ]),
        [
            trigger('fadeInOut', [
                state('void', style({
                    opacity: 0
                })),
                transition(':enter', [
                    animate('300ms ease-in', style({
                        opacity: 1
                    }))
                ]),
                transition(':leave', [
                    animate('300ms ease-out', style({
                        opacity: 0
                    }))
                ])
            ])
        ]
    ]
})


export class PeopleAssistantModalComponent extends ComponentFullView implements OnInit, OnDestroy {
    @ViewChild('checkbox') checkbox!: ElementRef<HTMLInputElement>;
    @ViewChild('textInput') textInputRef!: ElementRef<HTMLInputElement>;

    permission = [
         'celine.barreteau-daubech@akto.fr',
         'ngadmin',
         'vanessa.miranda@akto.fr',
         'neus.lebras-juarez@akto.fr',
         'zoe.canet@akto.fr',
         'emmanuelle.woiemberghe.sofip@biogaran.fr',
         'daphne.schneider.sofip@biogaran.fr',
         'maeva.buchheit.part@biogaran.fr',
         'aurelie.gendre.sofip@biogaran.fr',
         'jerome.rheel.sofip@biogaran.fr',
         'apons@pbc-concept.com',
         'jessica@avantagesrh.re',
         'dtvcoaching@gmail.com',
         'angers@dlg49.fr',
         'dorinef@hotmail.fr',
         'christophe.couvidou@gmail.com',
         'Muriel.husson@intalents.fr',
         'stephane@avantagesrh.com',
         'clement@clement-bergon.com',
         'marie.charcosset@ngenio-france.com',
         'nicolas.bonnin@ngenio-france.com',
         'patricia.bourbon.fpa1@gmail.com',
         'jleclerc@mecart.com',
         'pouellet@mecart.com',
         'vroberge@mecart.com',
         'pfillion@mecart.com',
         'pgenois@mecart.com',
         'clafortune@polrcorp.com',
         'stremblay@polrcorp.com',
         'mlemieux@mecart.com',
         'sjutras@effyjiecoaching.com',
         'jlemay@effyjiecoaching.com',
         'edith@edithcabot.ca',
         'simon.dubreuil@groupeij.com',
         'france.despatie@opty-management.com',
         'mvallee@synapsconnexion.com',
         'celine@celinemoisan.com',
         'karine@buziness.ca',
         'gabriel.wagner@infopech.org',
         'joelle.lauze@infopech.org',
         'nathalie.giguere@infopech.org',
         'jeff.menard@bc-assur.com',
         'marie-pierre.fortin@bc-assur.com',
         'alamontagne@assuranciagt.ca',
         'carol-ann.hubert@venneetfille.ca',
         'ngauthier@assuranciagt.ca',
         'f.lepage@lacetdebottine.ca',
         'mc.imbeault@lacetdebottine.ca',
         'william@simpliphi.io',
         'mgirard@nanuk.com',
         'Diane.Boudoul@ville.valdor.qc.ca',
         'smercier@nutrigroupe.ca',
         'tcocco@nutrigroupe.ca',
         'louisfelix.larochemorin@alithya.com',
         'sylvain.boulanger@nordic.ca',
         'anne-marie.doss@averna.com',
         'jamd@averna.com',
         'annie.turcotte@promutuel.ca',
         'olivier.hamel@promutuel.ca',
         'CREACORDH',
         'NicolePare',
         'Patti',
         'c.martin@trio-tech.com',
         'm.venne@trio-tech.com',
         'a.beaudet@trio-tech.com',
         'chantal.chicoine@polykar.com',
         'fsimard@phdemers.com',
         'myriam.lalonde@safrangroup.com',
         'amelie.brodeur@safrangroup.com',
         'isabelle.perreault@safrangroup.com',
         'melissa.desjardins@safrangroup.com',
         'karinejodouin@expertdent.ca',
         'carolineadam@expertdent.ca',
         'josiannebousquet@expertdent.ca',
         'stephaniemartin@expertdent.ca',
         'sebastienbrisebois@expertdent.ca',
         'veronickvallee@expertdent.ca',
         'patricia.bernier@delpharm.ca',
         'vanessa.ostiguy@delpharm.ca',
         'gestion@dentisteriemoma.com',
         'lhblais@precigrafik.com',
         'sara.bouchard@omniplast.com',
         'mchouinard@nmedia.ca',
         'jlachance@nmedia.ca',
         'chantal.lamoureux@institutpf.org',
         'ktessier@skibromont.com',
         'mfortier@skibromont.com',
         'sophie@vaillance.ca',
         'couillardconseils@outlook.com',
         'luc@cardinal.zone',
         'nancycnormand@lesalchimistesconseils.com',
         'AshleyRambo',
         'nancy@lesalchimistesconseils.com',
         'roxane@lesalchimistesconseils.com',
         'michel@lesalchimistesconseils.com',
         'josianne@lesalchimistesconseils.com',
         'malary@unison.works',
         'mbernier@unison.works',
         'na@alchimistesworld.com',
         'DISTRIBUTORACCOUNT',
         'ashley.rambo@gmail.com',
         'blake@xp3talent.com',
         'caroline.tardif@sm-inc.com',
         'chris@christhehrpro.com',
         'craig@jacobusassociates.com',
         'jackie.schetter@gmail.com',
         'jaminbaxter@legacybc.net',
         'jimhess@legacybc.net',
         'jodwoods@legacybc.net',
         'johnbarrett@legacybc.net',
         'leigh@chr.coach',
         'mike@XP3Talent.com',
         'drnateklein@gmail.com',
         'patti@pattiseda.com',
         'roland@cajer.com.co',
         'sabrina@xp3talent.com',
         'sandi.brinson@jabenefits.com',
         'sailu.timbo@creativeplanning.com',
         'monica.julo@creativeplanning.com',
         'todd@ngeniousa.com',
         'laura@ngeniousa.com',
         'gentry@cmamidwest.com',
         'john.copeland@cmamidwest.com',
         '2josianne@lesalchimistesconseils.com',
         'LauraG',
         'ToddG'
        ];

    txt = 'JIL';
    characters = this.txt.split('');
    reportWidth: string;
    sideWidth: string;
    small = true;
    hideGraph = false;
    public close: EventEmitter<any> = new EventEmitter();
    private subscriptions = new Subscription();
    private loadPeopleListSubscriptions = new Subscription();
    private termsSubscriptions: Subscription = new Subscription();
    private onDestroy = new Subject<void>();

    selectedReportName: string = '';

    otherIconsFolder = 'assets/other_icons/';

    feedbackMode: boolean = false;
    feedback: string = '';
    feedbackType: string = '';
    feedbackSubmitted = false;
    questionAsked: boolean = false;

    showReasons: boolean = false;

    reasons: any[] = [];
    selectedReason: any;
    reasonSelected: boolean = false;
    responseReceived: boolean = false;

    questionSent: boolean = false;

    sectionVisible: boolean = true;
    iconClicked: boolean = false;

    chatWindowVisible: boolean = false;

    userInput: string;
    userQuestion: string;
    messages = { response: '' };
    response = '';

    isExperimental = false;
    isShortAnswer = false;

    loadingState = 0;
    additionalLoading: boolean = false;
    loadingIntervalId: any;
    timeoutId: any;


    // *************************************************
    // https://ngenio.atlassian.net/browse/MPO-3792    *
    // Pour la démo en France nous desactivons le      *
    // requis d'acceptation des termes et conditions   *
    // *************************************************
    firstTimeUser = false;
    isErrorMessage = false;
    selectedQuestion: string;
    openSidebar = false;
    aiContext = 'traits';
    copySuccess = false;
    currentSelection: string;
    actualContext = {};
    placeholderText: string;

    showPcrMessage: boolean = false;

    termsOfServiceHtml: string;

    report: any;
    selectedCategory: any;
    categorySelected = false;
    langChangeSubscription: Subscription;
    reducedChatHeight: boolean = false;
    reduceChatHeightFeedback: boolean = false;

    selectedOption = 'traits';

    lastAskedQuestion: string;

    isHelpMenuVisible: boolean = false;
    isFAQVisible: boolean = false;
    isTermsVisible: boolean = false;

    isTopMenuVisible: boolean = false;

    termsAccepted: boolean = false;
    termsAcceptedConfirmed: boolean = false;

    isInputFocused: boolean = false;
    hasAskedQuestion: boolean = false;

    isSmallViewport: boolean = false;
    protected isMobileView: boolean = false;

    hamburgerMenuIsActive: boolean = false;
    isMobileComponentClosed: boolean = false;
    cards: any[] = [];

    isSafari: boolean = false;

    buttonStateAndIndex = {
        personaliteSelected: { selected: false, categoryIndex: 0 },
        pcrSelected: { selected: false, categoryIndex: 1 },
        // talentsSelected: { selected: false, categoryIndex: 2 }, // Uncomment if talents are re added
        iacSelected: { selected: false, categoryIndex: 3 },
        duoSelected: { selected: false, categoryIndex: 4 },
    };

    isDuoModalVisible: boolean = false;
    peopleList: Array<{ id: number, firstName: string, lastName: string }> = [];
    filteredPeopleList: Array<{ id: number, firstName: string, lastName: string }> = [];
    currentPage: number = 0;
    pageSize: number = 100;
    duoIsLoading: boolean = false;
    totalRecords: number = 0; // Total number of records
    searchQuery: string = '';
    selectedPersonName: string = '';
    selectedPersonScores: any = null;
    searchInitiated: boolean = false;
    mainIndividual: any;
    isDuoMode: boolean = false;

    //**************************************** */
    // Mobile and virtual keyboard controls
    //**************************************** */
    // isKeyboardOpen: boolean = false;
    // initialViewportHeight: number = window.innerHeight;
    private resizeSubscription: Subscription;

    constructor(
        public bsModalRef: BsModalRef,
        public cd: ChangeDetectorRef,
        protected stateService: StateService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected person: PersonService,
        protected translateService: TranslateService,
        protected user: UserService,
        protected deviceService: DeviceDetectorService,
        private apiPeople: ApiPeopleService,
        private apiJobs: ApiJobsService,
        private http: HttpClient,
        private peopleModalHandlingService: PeopleModalHandlingService,
        private peopleListService: PeopleList
    ) {
        super(stateService, translateService, router, deviceService);

        this.loadQuestions();
        this.translateService.onLangChange.subscribe(() => this.loadQuestions());

        this.loadReasons();
        this.translateService.onLangChange.subscribe(() => this.loadReasons());

        this.loadRandomQuestionsForMobile();
        this.translateService.onLangChange.subscribe(() => this.loadRandomQuestionsForMobile());

        this.checkViewportHeight();
    }


    checkViewportHeight() {
        this.isSmallViewport = window.innerHeight < 925;
    }

    ngOnInit() {
        this.mainIndividual = this.person.fullName;
        this.isSafari = this.getBrowser() === 'Safari';
        this.checkTermsAcceptance();
        // console.log('Viewport inner width:', window.innerWidth);
        // console.log('Viewport inner height:', window.innerHeight);
        // Existing initialization code
        // this.initialViewportHeight = window.innerHeight;

        const resize$ = fromEvent(window, 'resize').pipe(debounceTime(200));
        this.resizeSubscription = resize$.subscribe((event: Event) => this.onResize(event));

        this.isMobileView = window.innerWidth < 821;
        window.addEventListener('resize', this.onResize.bind(this));
        // Sidebar
        this.small = window.innerWidth < 1235;
        this.stateService.people.stateChanged$.subscribe((res) => {
            if (res) {
                this.cd.markForCheck();
            }
        });
        this.hideGraph = true;
        this.subscriptions.add(
            this.apiJobs.jobs([{ fields: 'id' }]).subscribe((checkJobs) => {
                this.hideGraph = false;
                this.cd.markForCheck();
            }),
        );
        // Set list width
        this.computeReportWidth(window);
        const reportType = this.stateService.people.reportType;
        switch (reportType) {
            case 'personality':
                this.toggleButton('personaliteSelected');
                this.currentSelection = 'personaliteSelected';
                this.selectedOption = 'traits';
                this.aiContext = 'traits';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                break;
            case 'perceptions':
                this.toggleButton('pcrSelected');
                this.currentSelection = 'pcrSelected';
                this.selectedOption = 'perceptions';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_perceptions';
                this.aiContext = 'perceptions';
                break;
            // Uncomment if talents are re added
            // case 'talents':
            //     this.toggleButton('talentsSelected');
            //     this.placeholderText = 'commons.assistantVirtuel_input_placeholder_talents';
            //     this.aiContext = 'talents';
            //     break;
            case 'iac':
                this.toggleButton('iacSelected');
                this.currentSelection = 'iacSelected';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_iac';
                this.aiContext = 'iac';
                break;
            case 'duo':
                this.toggleButton('duoSelected');
                this.currentSelection = 'duoSelected';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_duo';
                this.aiContext = 'duo';
                break;
            default:
                this.toggleButton('personaliteSelected');
                this.currentSelection = 'personaliteSelected';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder';
                this.aiContext = 'traits';
                break;
        }
        this.loadQuestions();
        // console.log('isMobileView:', this.isMobileView); // Add this line
        if (this.isMobileView) {
            this.loadRandomQuestionsForMobile();
        } else {
            this.loadQuestions();
        }
        this.loadRandomQuestionsForMobile();
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: { lang: string }) => {
            this.translateService.getTranslation(event.lang).subscribe(() => {
                this.loadQuestions();
                this.loadReasons();
                this.loadRandomQuestionsForMobile();
                this.resetSearch();

                // Reset all button states
                Object.keys(this.buttonStateAndIndex).forEach(key => {
                    this.buttonStateAndIndex[key].selected = false;
                });
                // Select the Personality button
                this.buttonStateAndIndex.personaliteSelected.selected = true;
                this.selectedOption = 'traits';

                this.cd.detectChanges();
            });
        });
        // console.log(this.statePeople.reportType, this.person.personalityScores);
        if ((this.statePeople.reportType === 'mpo' || this.statePeople.reportType === 'personality') && this.person.personalityScores === undefined) {
           this.switchEvaluationType('iac');
           this.aiContext = 'iac';
           this.toggleButtonMobile('iacSelected');
        } else if (this.statePeople.reportType === 'iac' && this.person.iacScores === undefined) {
            this.switchEvaluationType('mpo');
            this.aiContext = 'personality';
            this.toggleButtonMobile('personaliteSelected');
        }
        // console.log(this.aiContext);
        this.loadPeopleList();
        this.filteredPeopleList = this.peopleList;
    }

    loadPeopleList(search: string = ''): void {
        if (this.duoIsLoading) return;

        this.duoIsLoading = true;
        const { listObservable, recordsLoaded } = this.peopleListService.getListDuo(
            search,
            this.currentPage * this.pageSize,
            this.totalRecords,
            true
        );

        const loadPeopleListSubscriptions = listObservable.subscribe(
            (response: any) => {
                this.peopleList = search ? response.data : [...this.peopleList, ...response.data];

                if (search) {
                    const lowerSearch = search.toLowerCase();
                    const tokens = lowerSearch.split(' ').filter(token => token);

                    this.filteredPeopleList = this.peopleList.filter(person => {
                        const firstName = person.firstName.toLowerCase();
                        const lastName = person.lastName.toLowerCase();
                        const fullName = `${firstName} ${lastName}`;

                        const fullNameMatches = fullName.startsWith(lowerSearch);

                        const tokensMatch = tokens.every(token =>
                            firstName.startsWith(token) || lastName.startsWith(token)
                        );

                        return fullNameMatches || tokensMatch;
                    });
                } else {
                    this.filteredPeopleList = this.peopleList;
                }

                this.cd.detectChanges();

                this.currentPage++;
                this.totalRecords = response.count !== undefined ? response.count : this.peopleList.length;
                this.duoIsLoading = false;
                this.totalRecords = response.total || this.totalRecords;
            },
            (error: any) => {
                console.error('Error loading people list', error);
                this.duoIsLoading = false;
            }
        );

        this.subscriptions.add(loadPeopleListSubscriptions);
    }

    onScroll(event: any): void {
        const element = event.target;
        const atBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 100; // 100px buffer

        if (atBottom && !this.duoIsLoading && this.peopleList.length < this.totalRecords) {
            // console.log('Scrolled near the end, loading more records...');
            this.loadPeopleList(this.searchQuery);
        }
    }

    onSearch(searchTerm: string): void {
        const trimmedSearch = searchTerm.trim();

        if (!trimmedSearch) {
            console.warn('Search term is empty. Resetting search results.');
            this.resetSearch();
            return;
        }

        // console.log(`Searching for: ${trimmedSearch}`);
        this.searchQuery = trimmedSearch;
        this.currentPage = 0;
        this.peopleList = [];
        this.filteredPeopleList = [];
        this.totalRecords = 0;
        this.searchInitiated = true;
        this.loadPeopleList(this.searchQuery);
    }

    selectPerson(person: { id: string, firstName: string, lastName: string }) {
        this.selectedPersonName = `${person.firstName} ${person.lastName}`;
        this.isDuoModalVisible = false; // Hide the modal immediately
        this.isDuoMode = true;

        // Fetch the real scores using the API
        this.apiPeople.record([person.id]).subscribe(
            (response: any) => {
                // console.log('Fetched selected person:', response);

                // Access scores from evaluations.mpo.scores
                if (response && response.evaluations && response.evaluations.mpo && response.evaluations.mpo.scores) {
                    this.selectedPersonScores = response.evaluations.mpo.scores;
                    // console.log('Fetched selected person scores:', this.selectedPersonScores);
                } else {
                    console.warn('No scores found in the response:', response);
                    this.selectedPersonScores = null;
                }

                // Trigger change detection to update the UI
                this.cd.detectChanges();

                // Load questions after scores are fetched
                this.loadQuestions();
                this.loadRandomQuestionsForMobile();
                this.cd.detectChanges();
            },
            (error: any) => {
                console.error('Error fetching person scores:', error);
                this.selectedPersonScores = null;

                // Trigger change detection to reflect the error state
                this.cd.detectChanges();

                // Load questions even if there's an error
                this.loadQuestions();
            }
        );
        this.loadRandomQuestionsForMobile();
        this.cd.detectChanges();
    }

    removeSelectedPerson() {
        this.selectedPersonName = '';
        this.isDuoModalVisible = true;
        this.resetSearch();
        this.loadQuestions();
        this.loadRandomQuestionsForMobile();
        this.goBack()
    }

    closeDuoModalAndToggleDuo(): void {
        this.closeDuoModal();

        if (this.buttonStateAndIndex.duoSelected.selected) {
            this.toggleButton('personaliteSelected');
            this.isDuoMode = false;
        }
    }

    closeDuoModal() {
        this.isDuoModalVisible = false;
        this.searchInitiated = false;
        this.searchQuery = '';
        this.peopleList = [];
        this.filteredPeopleList = [];
        this.currentPage = 0;
    }

    resetSearch(): void {
        this.searchQuery = '';
        this.currentPage = 0;
        this.peopleList = [];
        this.filteredPeopleList = [];
        this.totalRecords = 0;
        this.searchInitiated = false;
        this.cd.detectChanges();
        // console.log('Search has been reset.');

        // Optionally, reload the initial list if needed
        this.loadPeopleList();
    }

    getBrowser(): string {
        const userAgent = navigator.userAgent;
        if (userAgent.includes("Chrome")) {
            return "Chrome";
        } else if (userAgent.includes("Firefox")) {
            return "Firefox";
        } else if (userAgent.includes("Safari")) {
            return "Safari";
        } else if (userAgent.includes("Edge")) {
            return "Edge";
        } else {
            return "Unknown";
        }
    }



    checkTermsAcceptance() {
        const localStorageKey = 'termsAccepted';
        const localAccepted = localStorage.getItem(localStorageKey);

        if (localAccepted === 'true') {
          // User has accepted terms previously
          this.termsAcceptedConfirmed = true;
        } else {
          // Check with the API
          const apiAccepted = this.person.hasAcceptedAiTerms;
          if (apiAccepted) {
            // API confirms acceptance
            this.termsAcceptedConfirmed = true;
            localStorage.setItem(localStorageKey, 'true');
          }
          // If API does not confirm, the modal remains visible
        }
      }



    loadReasons() {
        const lang = this.translateService.currentLang;
        const fileName = `assets/assistant-thumb-down-reasons/reasons_${lang}.json`;
        this.http.get(fileName).subscribe((data: any) => {
            this.reasons = data[0].reasons;
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // re-Set list width
        // console.log('onResize triggered');
        this.computeReportWidth(event.target);
        this.small = event.target.innerWidth < 1235;

        this.isMobileView = window.innerWidth < 821;

        this.checkViewportHeight();
        // this.checkKeyboard();
        this.cd.markForCheck();
    }

    // checkKeyboard() {
    //     const currentHeight = window.innerHeight;
    //     const heightDifference = this.initialViewportHeight - currentHeight;
    //     // Adjust threshold as needed
    //     this.isKeyboardOpen = heightDifference > 200;
    //     console.log('Height difference:', heightDifference);
    //     console.log('Keyboard open:', this.isKeyboardOpen);

    //     if (!this.isKeyboardOpen) {
    //         this.initialViewportHeight = currentHeight;
    //     }
    // }

    computeReportWidth(startWidth) {
        let adjustment: any = { left: -5, right: -8 };
        let widthRatio: number = 2 / 3;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (startWidth.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.reportWidth = (innerWidth - leftColumnWidth - 39) * widthRatio + adjustment.left + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth - 39) * (1 - widthRatio) + adjustment.right + 'px';
        this.sideWidth = '400px';
        this.reportWidth = innerWidth - 550 + 'px';
        return;
    }

    startLoadingAnimation() {
        this.loadingState = 0;
        this.additionalLoading = false;

        this.loadingIntervalId = setInterval(() => {
            this.loadingState = (this.loadingState + 1) % 4;
            this.cd.detectChanges(); // Trigger change detection
        }, 500);

        this.timeoutId = setTimeout(() => {
            this.additionalLoading = true;
            this.cd.detectChanges(); // Trigger change detection
        }, 9000);
    }

    stopLoadingAnimation() {
        if (this.loadingIntervalId) {
            clearInterval(this.loadingIntervalId);
            this.loadingIntervalId = null;
        }
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        this.cd.detectChanges(); // Trigger change detection
    }


    askQuestion() {
        if (this.questionAsked) { // Ensure not to call submitFeedback on the first question
            this.submitFeedback();
            this.reasonSelected = false;
        }
        if (this.isInputInvalid) {
            return;
        }
        this.hasAskedQuestion = true;
        this.isErrorMessage = false;
        this.messages = { response: '' };
        this.additionalLoading = false;
        if (this.selectedQuestion) {
            this.userInput = (' ' + this.selectedQuestion).slice(1);
        }
        this.userQuestion = (' ' + this.userInput).slice(1);
        this.questionAsked = true;
        this.chatWindowVisible = true;
        this.reducedChatHeight = true;
        this.sectionVisible = false;
        this.cd.detectChanges();
        this.cd.markForCheck();
        this.questionSent = true;
        this.cd.detectChanges();
        this.cd.markForCheck();
        this.responseReceived = true; // Disable buttons initially

        this.startLoadingAnimation();

        // Set lastAskedQuestion before clearing selectedQuestion
        this.lastAskedQuestion = this.userInput; // Store the last question asked in a different variable
        this.cd.detectChanges();
        this.cd.markForCheck();

        const currentLang = this.translateService.currentLang;
        const data = this.gatherQuestionContext();
        this.apiPeople
            .questionAi(data, this.aiContext)
            .pipe(
                timeoutWith(30000, throwError('people.commons.request_timeout_error')),
                catchError((error) => {
                    let errorKey;
                    if (error.status === 500) {
                        errorKey = 'people.commons.server_error';
                    } else if (error === 'people.commons.request_timeout_error') {
                        errorKey = 'people.commons.request_timeout_error';
                    } else {
                        errorKey = error;
                    }

                    return this.translateService.get(errorKey).pipe(
                        switchMap((translatedErrorMessage) => {
                            clearInterval(this.loadingIntervalId);
                            clearTimeout(this.timeoutId);
                            this.isErrorMessage = true;
                            console.error('An error occurred:', error);
                            this.responseReceived = true; // Enable buttons on error
                            this.questionSent = true;
                            this.cd.detectChanges();
                            this.cd.markForCheck();
                            return throwError(translatedErrorMessage);
                        }),
                    );
                }),
            )
            .subscribe(
                (response: any) => {
                    this.stopLoadingAnimation();
                    clearInterval(this.loadingIntervalId);
                    clearTimeout(this.timeoutId);
                    this.actualContext = response;
                    this.messages = {
                        response: MarkdownToHtmlHelper.markdownToHtml(response.response),
                    };
                    this.responseReceived = true; // Enable buttons on success
                    this.reducedChatHeight = false;
                    this.questionSent = true;
                    this.isErrorMessage = false;
                    this.sectionVisible = false;
                    this.selectedQuestion = '';
                    this.userInput = ''; // Reset userInput after submission
                    this.cd.detectChanges();
                    this.cd.markForCheck();
                },
                (error) => {
                    this.stopLoadingAnimation();
                    this.messages = {
                        response: error,
                    };
                    this.responseReceived = true; // Enable buttons on error
                    this.questionSent = true;
                    this.isErrorMessage = true;
                    this.sectionVisible = false;
                    this.userInput = ''; // Reset userInput after error
                    this.cd.detectChanges();
                    this.cd.markForCheck();
                },
            );

        // Clear the input field after the question is asked
        this.selectedQuestion = '';
        this.userInput = ''; // Ensure userInput is cleared
    }


    submitFeedback() {
        // Gather the context data
        const context = this.gatherQuestionContext();
        context.feedback = this.selectedReason ? this.selectedReason.reasonText : this.feedback;
        context.feedbackType = this.feedbackType;
        context.response = this.messages.response;
        // Save the context
        this.saveContext(context);

        // Reset the necessary properties
        this.userInput = '';
        this.userQuestion = '';
        this.selectedReason = null; // Reset the selected reason
        this.messages = { response: '' };
        this.questionAsked = false;
        this.feedbackMode = false;
        this.iconClicked = false;
        this.feedbackType = ''; // Reset feedback type

        this.feedbackSubmitted = true;
    }

    submitThumbsUp() {
        this.iconClicked = !this.iconClicked;
        this.selectedReason = null;
        if (this.iconClicked) {
            this.feedbackMode = false; // Ensure thumbs down is not selected
            this.showReasons = false; // Hide reasons when thumbs up is clicked
            this.feedbackType = 'thumbsUp'; // Set feedback type to thumbs up
            this.feedbackMode = false;
        } else {
            this.feedbackType = ''; // Reset feedback type if unclicked
        }
    }

    saveContext(context: DataType) {
        this.apiPeople.saveAiContext(context).subscribe(() => { });
    }

    selectReason(reason: any) {
        this.selectedReason = reason;
        this.reasonSelected = true;
        this.showReasons = false;
    }

    giveFeedback() {
        this.feedbackMode = !this.feedbackMode;
        if (this.feedbackMode) {
            this.iconClicked = false; // Ensure thumbs up is not selected
            this.showReasons = true;
            this.reduceChatHeightFeedback = true;
            this.feedbackType = 'thumbsDown'; // Set feedback type to thumbs down
            if (this.sectionVisible) {
                this.toggleSection(); // Collapse the section if it's visible
            }
        } else {
            this.showReasons = false;
            this.reduceChatHeightFeedback = false;
            this.feedbackType = ''; // Reset feedback type if unclicked
            // this.reducedChatHeight = false;
        }
    }

    acceptTerms() {
        this.firstTimeUser = false;
        this.termsAccepted = this.checkbox.nativeElement.checked;

    }

    closeModal(): void {
        this.close.emit({ closed: true });
        this.bsModalRef.hide();
    }

    thumbsIconClicked() {
        this.iconClicked = !this.iconClicked;
    }

    onQuestionSelected(question: string) {
        this.selectedQuestion = question;
    }

    copyToClipboard() {
        let responseText = this.HTMLToText(this.messages?.response);
        this.translateService.get(['people.commons.question', 'people.commons.response']).subscribe((translations) => {
            const clipboardText = `${translations['people.commons.question']}: ${this.userQuestion}\n\n${translations['people.commons.response']}: ${responseText}`;

            navigator.clipboard.writeText(clipboardText).then(() => {
                this.copySuccess = true;
                this.cd.detectChanges();

                setTimeout(() => {
                    this.copySuccess = false;
                    this.cd.detectChanges();
                }, 2000);
            });
        });
    }

    toggleButton(button: string) {
        this.resetSearch();
        this.loadQuestions();
        if (this.buttonStateAndIndex[button].selected) {
            return;
        }

        // Reset all button states
        Object.keys(this.buttonStateAndIndex).forEach(key => {
            this.buttonStateAndIndex[key].selected = false;
        });
        // console.log('Reset all button states:', this.buttonStateAndIndex);

        // Set the selected button
        this.buttonStateAndIndex[button].selected = true;
        // console.log(`Button ${button} is now selected.`);

        this.showPcrMessage = false;
        this.aiContext = 'traits'; // Default context
        this.userInput = '';
        this.selectedQuestion = '';

        let reportType = '';

        if (button === 'personaliteSelected') {
            this.selectedOption = 'traits';
            this.aiContext = this.selectedOption;
            this.showPcrMessage = true;
            reportType = 'personality';
            this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_personnality_traits';
        }
        // Uncomment if talents are re added
        // else if (button === 'talentsSelected') {
        //     this.aiContext = 'talents';
        //     this.showPcrMessage = false;
        //     reportType = 'talents';
        //     this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_talents';
        // }
        else if (button === 'iacSelected') {
            this.aiContext = 'iac';
            this.showPcrMessage = false;
            reportType = 'iac';
            this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_iac';
        }
        else if (button === 'duoSelected') {
            this.aiContext = 'duo';
            this.showPcrMessage = false;
            reportType = 'duo';
            this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_duo';
            this.isDuoModalVisible = true; // Show the popover
        }
        // else if (button === 'pcrSelected') {
        //     this.aiContext = 'pcr';
        //     this.showPcrMessage = false;
        //     reportType = 'pcr';
        //     this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_personnality_perceptions';
        //     this.selectedOption = 'perceptions';
        //     this.onRadioChange();
        // }

        // Reset `selectedPersonName` if not selecting DUO
        if (button !== 'duoSelected') {
            this.isDuoModalVisible = false;
            this.selectedPersonName = ''; // Reset the selected person name
            // console.log('selectedPersonName reset:', this.selectedPersonName);
        }

        switch (this.aiContext) {
            case 'traits':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                break;
            case 'pcr':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_perceptions';
                break;

            // case 'talents':
            //     this.placeholderText = 'commons.assistantVirtuel_input_placeholder_talents';
            //     break;
            case 'iac':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_iac';
                break;
            case 'duo':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_duo';
                break;
            default:
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder';
                break;
        }

        this.selectedQuestion = '';
        this.switchEvaluationType(reportType);
        this.cd.detectChanges();
    }

    toggleButtonMobile(button: string) {
        if (this.buttonStateAndIndex[button].selected) {
            return;
        }

        Object.keys(this.buttonStateAndIndex).forEach(key => {
            this.buttonStateAndIndex[key].selected = false;
        });

        // console.log('Reset all button states:', this.buttonStateAndIndex);

        for (let key in this.buttonStateAndIndex) {
            this.buttonStateAndIndex[key].selected = false;
        }

        this.buttonStateAndIndex[button].selected = true;
        // console.log(`Button ${button} is now selected.`);
        this.showPcrMessage = false;
        this.aiContext = 'traits'; // This line will be updated below
        this.userInput = '';
        this.selectedQuestion = '';

        let reportType = '';

        if (button === 'personaliteSelected') {
            this.selectedOption = 'traits'; // **Added Line**
            this.aiContext = this.selectedOption;
            this.showPcrMessage = true;
            reportType = 'personality';
            this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_personnality_traits';
        }
        // Uncomment if talents are re added
        // else if (button === 'talentsSelected') {
        //     this.aiContext = 'talents';
        //     this.showPcrMessage = false;
        //     reportType = 'talents';
        //     this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_talents';
        // }
        else if (button === 'iacSelected') {
            this.aiContext = 'iac';
            this.showPcrMessage = false;
            reportType = 'iac';
            this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_iac';
        }
        else if (button === 'duoSelected') {
            this.aiContext = 'duo';
            this.showPcrMessage = false;
            reportType = 'duo';
            this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_duo';
            this.isDuoModalVisible = true; // Show the popover
        }
        // else if (button === 'pcrSelected') {
        //     this.aiContext = 'pcr';
        //     this.showPcrMessage = false;
        //     reportType = 'pcr';
        //     this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_personnality_perceptions';
        //     this.selectedOption = 'perceptions';
        //     this.onRadioChange();
        // }

        switch (this.aiContext) {
            case 'traits':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                break;
            case 'pcr':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_perceptions';
                break;

            // case 'talents':
            //     this.placeholderText = 'commons.assistantVirtuel_input_placeholder_talents';
            //     break;
            case 'iac':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_iac';
                break;
            case 'duo':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_duo';
                break;
            default:
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                break;
        }
        this.selectedQuestion = '';
        this.switchEvaluationType(reportType);
        // console.log('Inside toggle button', this.aiContext)
        this.cd.detectChanges();
    }

    // Ensure switchEvaluationType is accessible in this component
    switchEvaluationType(newType: string) {
        // console.log('New report type:', newType);
        if (!this.peopleModalHandlingService.checkReporUnaccessble(newType)) {
            let change = { reportType: newType };
            this.statePeople.reportType = (newType === 'perceptions') ? 'personality' : newType;
            this.statePeople.stateChanged.next(change);
        }
    }

    onRadioChange(option?: string) {
        let reportType = '';
        if (option) {
            this.selectedOption = option;
        }

        if (this.selectedOption === 'traits') {
            this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_personnality_traits';
        } else if (this.selectedOption === 'perceptions') {
            this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_personnality_perceptions';
        }

        // Select the perceptions button only if 'perceptions' is selected
        if (this.selectedOption === 'perceptions') {
            this.buttonStateAndIndex['pcrSelected'].selected = true;
        } else {
            this.buttonStateAndIndex['pcrSelected'].selected = false;
        }

        // Update aiContext based on the selected option
        this.aiContext = this.selectedOption;
        this.placeholderText = this.selectedOption === 'traits'
            ? 'commons.assistantVirtuel_input_placeholder_traits'
            : 'commons.assistantVirtuel_input_placeholder_perceptions';
        reportType = this.selectedOption;

        // Switch evaluation type based on reportType
        // this.switchEvaluationType(reportType);

        // console.log('Inside onRadioChange', this.aiContext);
    }

    onRadioChangeMobile(option?: string) {
        let reportType = '';
        if (option) {
            this.selectedOption = option;
        }
        this.selectedReportName = 'commons.assistantVirtuel_mobile_menu_label_personnality_perceptions';

        // Deselect all buttons
        for (let key in this.buttonStateAndIndex) {
            this.buttonStateAndIndex[key].selected = false;
        }

        // Select the perceptions button
        this.buttonStateAndIndex['pcrSelected'].selected = true;

        // Update aiContext to 'perceptions'
        this.aiContext = 'perceptions';
        this.placeholderText = 'commons.assistantVirtuel_input_placeholder_perceptions';
        reportType = 'perceptions';

        // Switch evaluation type based on reportType
        this.switchEvaluationType(reportType);

        // console.log('Inside onRadioChange', this.aiContext);
    }


    toggleSidebar() {
        this.openSidebar = !this.openSidebar;
    }

    toggleCheckbox(event: Event) {
        event.preventDefault();
        this.checkbox.nativeElement.checked = !this.checkbox.nativeElement.checked;
        this.termsAccepted = this.checkbox.nativeElement.checked;
        this.acceptTerms();
    }

    // Method to toggle the visibility of the section and adjust the chat height accordingly
    toggleSection() {
        // Toggle the visibility of the section
        this.sectionVisible = !this.sectionVisible;
        // Set reducedChatHeight to true only when sectionVisible is true
        this.reducedChatHeight = this.sectionVisible;

        this.showReasons = false;

        // If the section is collapsed, go back to categories

        this.categorySelected = false;
    }

    HTMLToText(html: string): string {
        let text = html.replace(/<\/p>/g, '\n\n');
        text = text.replace(/<[^>]*>/g, '');
        text = text.replace(/\n\s*\n/g, '\n\n');
        return text;
    }

    isPcrValid(): number {
        let lastReport = this.person.lastPrbReport;
        if (lastReport && lastReport.info && lastReport.info.date) {
            let today = new Date();
            let prbDate = new Date(lastReport.info.date);
            let diffDate = Math.floor((today.getTime() - prbDate.getTime()) / 86400000);
            if (diffDate >= 120) {
                return 2;
            } else if (diffDate >= 60) {
                return 1;
            } else {
                return 0;
            }
        }
        return 0;
    }

    selectCategory(category) {
        this.selectedCategory = category;
        this.categorySelected = true;
    }
    goBack() {
        this.categorySelected = false;
    }

    selectQuestion(question: string) {
        // Create a temporary div element
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = question;

        // Extract the plain text without HTML tags
        const plainText = tempDiv.textContent || tempDiv.innerText || '';

        // Assign the plain text to selectedQuestion
        this.selectedQuestion = plainText;

        setTimeout(() => {
            if (this.textInputRef && this.textInputRef.nativeElement) {
                this.textInputRef.nativeElement.focus();
                if (!this.isMobileView) {
                    this.moveCursorToEnd(this.textInputRef.nativeElement);
                }
            } else {
                console.error('textInput is undefined');
            }
        }, 0);
    }

    loadQuestions() {
        const lang = this.translateService.currentLang;
        const fileName = `assets/assistant-questions/questions_${lang}.json`;
        this.http.get(fileName).subscribe((data: any[]) => {
            if (this.buttonStateAndIndex.duoSelected.selected && this.selectedPersonName) {
                this.report = data.find(report => report.reportId === "2");
            if (this.report) {
                this.report.questionsCategories.forEach(category => {
                    category.questions.forEach(q => {
                        if (q.question) {
                            q.question = q.question
                            .replace('individual_01', `<b>${this.mainIndividual}</b>`)
                            .replace('individual_02', `<b>${this.selectedPersonName}</b>`);
                        }
                    });
                });
            }
            } else {
                this.report = data.find(report => report.reportId === "1");
            }
            this.cd.detectChanges();
        });
    }

    loadRandomQuestionsForMobile() {
        const lang = this.translateService.currentLang;
        const fileName = `assets/assistant-questions/questions_${lang}.json`;
        this.http.get(fileName).subscribe((data: any[]) => {
            if (this.buttonStateAndIndex.duoSelected.selected && this.selectedPersonName) {
                const report = data.find(report => report.reportId === "2");
                if (report) {

                    // console.log('Report DUO found:', report);
                    const allQuestions = report.questionsCategories.flatMap(category => category.questions);
                    // console.log('All questions DUO:', allQuestions);
                    if (allQuestions.length > 0) {
                        const randomQuestions = this.getRandomQuestions(allQuestions, 5);
                        randomQuestions.forEach(q => {
                            if (q.question) {
                                q.question = q.question
                                    .replace('individual_01', this.mainIndividual)
                                    .replace('individual_02', this.selectedPersonName);
                            }
                        });
                        this.cards = randomQuestions.filter(question => question.questionId && question.question);
                        //  console.log('Random questions DUO loaded:', this.cards);
                    }
                }
            } else {
                const report = data.find(report => report.reportId === "1");
            if (report) {
                // console.log('Report found:', report);
                const allQuestions = report.questionsCategories.flatMap(category => category.questions);
                // console.log('All questions:', allQuestions);
                if (allQuestions.length > 0) {
                    const randomQuestions = this.getRandomQuestions(allQuestions, 5);
                    this.cards = randomQuestions.filter(question => question.questionId && question.question);
                    // console.log('Random questions loaded:', this.cards);
                }
            }
            }});
    }

    // Example implementation of getRandomQuestions
    getRandomQuestions(questions: any[], count: number) {
        const shuffled = questions.sort(() => 0.5 - Math.random());
        const selectedQuestions = shuffled.slice(0, count);
        // console.log('Selected questions:', selectedQuestions);
        return selectedQuestions;
    }

    refreshQuestion() {
        this.userInput = this.lastAskedQuestion;
        this.askQuestion();
    }

    moveCursorToEnd(inputElement: HTMLInputElement): void {
        setTimeout(() => {
            inputElement.scrollLeft = inputElement.scrollWidth;
        }, 0);
    }

    private gatherQuestionContext(): DataType {
        const currentLang = this.translateService.currentLang;
        let data: DataType = {
            question: this.userQuestion,
            currentLang: currentLang,
            experimental: this.isExperimental ? 'true' : 'false',
            personalityScores: {},
            person_1_scores: {},
            person_2_scores: {},
            person_1_name: this.person.fullName,
            person_2_name: this.selectedPersonName,

        };

        if (this.aiContext === 'traits' || this.aiContext === 'perceptions') {
            for (let trait of ['OR', 'SE', 'A', 'E', 'P', 'S']) {
                if (this.person.personalityScores[trait] !== undefined) {
                    data.personalityScores[trait] = this.person.personalityScores[trait].toString();
                    // console.log('Scores in personality', this.person.personalityScores[trait].toString());
                }
            }
            // console.log('Data:', data);
        }


        if (this.aiContext === 'iac') {
            if (this.person.iacNormalizedScore !== undefined) {
                data.iacScores = this.person.iacNormalizedScore.toString();
            }
        }

        if (this.aiContext === 'perceptions') {
            data.prbScores = {};
            for (let trait of ['OR', 'SE', 'A', 'E', 'P', 'S']) {
                if (this.person.prbScores[trait] !== undefined) {
                    data.prbScores[trait] = this.person.prbScores[trait].toString();
                }
            }
        }

        if (this.aiContext === 'duo') {
            for (let trait of ['OR', 'SE', 'A', 'E', 'P', 'S']) {
                if (this.person.personalityScores[trait] !== undefined && this.selectedPersonName && this.selectedPersonScores) {
                    data.person_1_scores[trait] = this.person.personalityScores[trait].toString();
                    data.person_2_scores[trait] = this.selectedPersonScores[trait].toString();
                }
            }
            data.person_1_name = this.person.fullName;
            data.person_2_name = this.selectedPersonName;
        }

        // if (this.aiContext === 'talents') {
        //     data.talentsScores = this.person.talentsReport.info.talentsScore;
        // }

        return data;
    }

    showFAQ() {
        this.isFAQVisible = true;
        this.isTermsVisible = false;
        this.isHelpMenuVisible = false;
    }

    hideFAQ() {
        this.isFAQVisible = false;
        this.isTermsVisible = false;
    }

    showTerms() {
        this.isFAQVisible = false;
        this.isTermsVisible = true;
        this.isHelpMenuVisible = false;
        // console.log('Terms are visible:', this.isTermsVisible);
    }

    hideTerms() {
        this.isFAQVisible = false; // or set to the appropriate state for the regular view
        this.isTermsVisible = false;
        this.isHelpMenuVisible = true; // or set to the appropriate state for the regular view
        // console.log('Terms and conditions modal hidden.');
    }

    continue() {
        if (this.termsAccepted) {
          this.termsAcceptedConfirmed = true;
          this.saveTermsAcceptance();
          this.hideTerms();
          // console.log('Continuing with accepted terms.');
        }
      }

      saveTermsAcceptance() {
        const localStorageKey = 'termsAccepted';
        localStorage.setItem(localStorageKey, 'true');

        // Replace 'user-id' with the actual user identifier required by your API
        const userId: string = this.stateService.session.sessionData.userData.id;
        const urlParams: [string] = [userId];

        this.subscriptions.add(
          this.apiPeople.setAcceptAiTerms(urlParams).subscribe(
            (response) => {
            //   console.log('Terms acceptance successfully saved to the backend.');
            },
            (error) => {
              console.error('Error saving terms acceptance to the backend:', error);
            }
          )
        );
      }

    toggleHelpMenu() {
        this.isHelpMenuVisible = !this.isHelpMenuVisible;
        // console.log('Help menu is visible:', this.isHelpMenuVisible);
    }

    toggleHamburgerMenu(): void {
        this.hamburgerMenuIsActive = !this.hamburgerMenuIsActive;
        this.isTopMenuVisible = !this.isTopMenuVisible;
        // console.log('Hamburger menu is active:', this.hamburgerMenuIsActive);
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event) {
        // console.log('Document clicked');
        const target = event.target as HTMLElement;
        if (!target.closest('.help-button-container')) {
            // console.log('Help menu is visible:', this.isHelpMenuVisible);
            this.isHelpMenuVisible = false;
        }
        if (!target.closest('.menu-container')) {
            this.isTopMenuVisible = false;
            this.hamburgerMenuIsActive = false;
            // console.log('Hamburger menu is active:', this.hamburgerMenuIsActive);
        }


    }

    onToggleSherpa(checked: boolean) {
        this.person.onToggleSherpa(this.person.id, this.stateService.people.report.type, checked);
        if (this.buttonStateAndIndex.duoSelected.selected) {
            this.toggleButton('personaliteSelected');
        }
    }

    onToggleMobileJil(checked: boolean) {
        this.person.onToggleMobileJil(this.person.id, this.stateService.people.report.type, checked);
    }

    ngOnDestroy() {
        if (this.buttonStateAndIndex.duoSelected.selected) {
            // Reset to 'personaliteSelected'
            this.toggleButton('personaliteSelected');

            // Navigate to '/personality' route
            this.router.navigate([
                '/people/report',
                this.person.id,      // Replace with the correct person ID
                'personality'
            ]);
        }
        window.removeEventListener('resize', this.onResize.bind(this));
        this.onDestroy.next();
        this.onDestroy.complete();
        this.submitFeedback();
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
        if(this.termsSubscriptions) {
            this.termsSubscriptions.unsubscribe();
        }

        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }

        if (this.loadPeopleListSubscriptions) {
            this.loadPeopleListSubscriptions.unsubscribe();
        }
    }

    get statePeople(): any {
        return this.stateService.people;
    }

    // people-assistant-modal.component.ts

get isInputInvalid(): boolean {
    if (this.aiContext === 'pcr' && this.isPcrValid() === 2) {
        return true;
    }
    return !this.selectedQuestion || this.selectedQuestion.trim().length < 1;
}
}
